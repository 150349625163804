








































































import {Component, Prop, Vue} from 'vue-property-decorator';
import WaveForm from './WaveForm.vue';
import {Track, TrackWave} from '@/types';
import {convertTimeHHMMSS} from '@/utils/convertTimeHHMMSS';

@Component({
  components: {
    WaveForm,
  },
})
export default class TrackPreview extends Vue {
  @Prop({required: true})
  private track!: Track;

  private duration = 0;
  private progress = 0;
  private audio = new Audio(this.track.url);
  private playing = false;

  private get wave(): TrackWave | null {
    return this.track.wave;
  }

  private get durationText() {
    return convertTimeHHMMSS(this.duration);
  }

  private togglePlay() {
    if (this.playing) {
      this.audio.pause();
    } else {
      this.audio.play();
    }

    this.playing = !this.playing;
  }

  private updateProgress(val: number) {
    this.progress = val;

    if (this.duration) {
      this.audio.currentTime = this.duration * this.progress;
    }
  }

  private created() {
    this.audio.addEventListener('timeupdate', this.onTimeUpdate);
    this.audio.addEventListener('loadedmetadata', this.onLoadedMetadata);
  }

  private destroyed() {
    this.audio.removeEventListener('timeupdate', this.onTimeUpdate);
    this.audio.removeEventListener('loadedmetadata', this.onLoadedMetadata);

    this.audio.pause();
    this.audio.src = '';
  }

  private onTimeUpdate() {
    this.progress = this.audio.currentTime / this.duration;
  }

  private onLoadedMetadata() {
    this.duration = this.audio.duration;
  }
}
