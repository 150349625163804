







import {Component, Prop, Watch, Vue} from 'vue-property-decorator';
import {TrackWave} from '@/types';

@Component
export default class WaveForm extends Vue {
    @Prop({required: true})
    private wave!: TrackWave;

    @Prop({type: Number, default: 0})
    private progress!: number;

    @Watch('progress')
    private progressChanged() {
        this.paint();
    }

    private paint() {
        const canvas = this.$refs.canvas as HTMLCanvasElement;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        const rectWidth = canvas.width / this.wave.length;
        const passed = Math.round(this.progress * canvas.width);

        for (let i = 0; i < this.wave.length; i++) {
            const e = this.wave[i];

            const start = e[0] || 49;
            const end = e[1] || 51;

            ctx.fillStyle = i < passed ? '#b59913' : '#dcdcdd';
            ctx.fillRect(i * rectWidth, start, rectWidth, end - start);
        }
    }

    private clickHandler(e: MouseEvent) {
        const canvas = this.$refs.canvas as HTMLCanvasElement;
        const newProgress = e.offsetX / canvas.offsetWidth;

        this.$emit('update-progress', newProgress);
    }

    private mounted() {
        this.paint();
    }
}
