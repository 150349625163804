










































































































import {Component, Vue} from 'vue-property-decorator';
import {Track} from '@/types';
import UBlock from '@/ui-lib/block/Block.vue';
import UAlert from '@/ui-lib/alert/Alert.vue';
import UButton from '@/ui-lib/button/Button.vue';
import TrackDistribute from '@/components/track/TrackDistribute.vue';
import {TrackDistributionService} from '@/api';
import {player} from '@/components/music-player/Player.vue';

@Component({
  components: {
    UBlock,
    UAlert,
    UButton,
    TrackDistribute,
  },
})
export default class TracksDistributeView extends Vue {
  private isLoading = false;
  private isLoadingSave = false;
  private isLoadingNotSuitable = false;
  private isLoadingSkip = false;
  private errorMessage = '';
  private track: Track | null = null;
  private left = 0;
  private selected = [];

  private async loadTrack() {
    this.isLoading = true;

    try {
      const data = await TrackDistributionService.load();
      this.track = data.track;
      this.left = data.left;
      this.selected = [];
    } catch (e) {
      this.errorMessage = e?.message || 'Ошибка';
    }

    this.isLoading = false;
  }

  private created() {
    player.close();
    this.loadTrack();
  }

  private async save() {
    if (!this.track) {
      return;
    }

    this.isLoadingSave = true;

    try {
      await TrackDistributionService.save(this.track.id, this.selected);

      this.loadTrack();
    } catch (e) {
      this.$notify({
        type: 'error',
        title: 'Ошибка',
        text: e?.message || 'Неизвестная ошибка',
      });
    }

    this.isLoadingSave = false;
  }

  private async notSuitable() {
    if (!this.track) {
      return;
    }

    this.isLoadingNotSuitable = true;

    try {
      await TrackDistributionService.notSuitable(this.track.id);

      this.loadTrack();
    } catch (e) {
      this.$notify({
        type: 'error',
        title: 'Ошибка',
        text: e?.message || 'Неизвестная ошибка',
      });
    }

    this.isLoadingNotSuitable = false;
  }

  private async skip() {
    if (!this.track) {
      return;
    }

    this.isLoadingSkip = true;

    try {
      await TrackDistributionService.skip(this.track.id);

      this.loadTrack();
    } catch (e) {
      this.$notify({
        type: 'error',
        title: 'Ошибка',
        text: e?.message || 'Неизвестная ошибка',
      });
    }

    this.isLoadingSkip = false;
  }
}
