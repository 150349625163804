















































import {Component, Prop, Vue} from 'vue-property-decorator';

interface Item {
    name: string;
    text: string;
}

@Component
export default class MuzRussiaComment extends Vue {
    @Prop({type: String, default: ''})
    private comment!: string | null;

    private names: Record<string, string> = {
        'target': 'Для кого',
        'genre': 'Жанры',
        'style': 'Стиль',
        'rate': 'Темп',
        'instr': 'Инструменты',
        'theme': 'Тема',
        'mood': 'Настроение',
        'lang': 'Язык',
        'perf': 'Голос',
    };

    private get items(): Item[] {
        const res: Item[] = [];

        if (this.comment) {
            this.comment.split(';').forEach((a) => {
                const r = a.split(':');

                if (r.length < 2) return;

                const id = r[0].trim();
                const text = r[1].trim();

                if (!(id in this.names)) return;
                if (!text) return;

                res.push({
                    name: this.names[id],
                    text: text,
                });
            });
        }

        return res;
    }
}
