






























































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Track} from '@/types';
import TrackPreview from './TrackPreview.vue';
import UBlock from '@/ui-lib/block/Block.vue';
import TrackSelectCompilations from './select-compilations/TrackSelectCompilations.vue';
import MuzRussiaComment from './MuzRussiaComment.vue';

@Component({
  components: {
    UBlock,
    TrackPreview,
    TrackSelectCompilations,
    MuzRussiaComment,
  },
})
export default class TrackDistribute extends Vue {
  @Prop({required: true})
  private track!: Track;

  @Prop({required: true})
  private selected!: number[];

  private get title(): string {
    return this.track.title;
  }

  private get artistName(): string {
    return this.track.artist.name;
  }

  private get comment(): string | null {
    return this.track.comment;
  }
}
